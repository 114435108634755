import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';

// Import Store
import myStore from './store/configureStore'
import history from './store/history';

// Import Styles: 
import './scss/index.scss'

// Import components
import homepage from './views/homepage';

const AppRoutes = () => (
    <Provider store={myStore}>
        <Router history={history}>
            <Switch>
                <Route path="/" component={homepage} />
            </Switch>
        </Router>
    </Provider>
);

ReactDOM.render(<AppRoutes />, document.getElementById('root'));
serviceWorker.unregister();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

import React, { Component } from 'react';

import "./styles.scss"
import Logo from '../logo/index';

export default class GlobalHeader extends Component {
    render() {
        console.log(process.env.PUBLIC_URL);
        return (
            <header className="main-header">
                <div className="header-left">
                    <a className="hlink" id="menulink" href="/menu">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" >
                            <g>
                                <path fill="#18f2b2" d="M82.9,31.3H17.1c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3h65.8c2.4,0,4.3,1.9,4.3,4.3S85.3,31.3,82.9,31.3z"></path>
                                <path fill="#18f2b2" d="M82.9,54.3H17.1c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3h65.8c2.4,0,4.3,1.9,4.3,4.3S85.3,54.3,82.9,54.3z"></path>
                                <path fill="#18f2b2" d="M82.9,77.3H17.1c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3h65.8c2.4,0,4.3,1.9,4.3,4.3S85.3,77.3,82.9,77.3z"></path>
                            </g>
                        </svg>		
                    </a>
                </div>
                <div className="header-centre">
                </div>
                <div className="header-right">
                    {/* <a className="hlink socli" href="#">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" >
                            <path fill="#00a6a6" d="M55.4,40.2c2.6,0,5.1,0,7.7-0.1c2.4,0,2.5,0,2.3,2.4c-0.2,2.5-0.5,4.9-0.7,7.4c-0.1,0.8-0.6,0.8-1.1,0.8
                                c-2.3,0-4.7,0.1-7,0c-0.9,0-1.2,0.2-1.2,1.2c0.1,9.4,0.2,18.7,0.2,28.1c0,1.4,0,1.4-1.4,1.4c-3.4,0-6.7,0-10.1,0.1
                                c-0.9,0-1.2-0.3-1.2-1.3c0-7.4-0.1-14.8-0.2-22.2c0-1.9,0-3.8,0-5.8c0-0.4,0-0.8,0-1.4c-0.9,0-1.7,0-2.5,0c-1.6,0-3.2,0-4.8,0
                                c-0.6,0-1-0.1-1-0.8c0-2.9,0-5.9-0.1-8.8c0-0.8,0.5-0.9,1.1-0.9c2.3,0,4.7,0,7.1-0.1c0-0.4,0.1-0.7,0.1-1c0.1-3.3,0-6.7,0.4-9.9
                                c0.8-5.8,4.3-9.2,10.1-10.3c3.9-0.8,7.7-0.5,11.6-0.2c0.7,0.1,0.9,0.4,0.9,1c0,2.7,0,5.3,0.1,8c0,0.8-0.4,1-1.1,1
                                c-1.9,0-3.8,0-5.8,0.1c-2.2,0.2-3.1,1.1-3.4,3.3c-0.1,0.5-0.1,1.1-0.2,1.7c0,2,0,3.9,0,5.9C55.3,39.9,55.3,40,55.4,40.2z"></path>
                            </svg>				        	
                    </a> */}
                    <a className="hlink socli" href="http://twitter.com/cubiqle" target='_blank' rel="noopener noreferrer">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" >
                            <path fill="#18f2b2" d="M82.4,30.5c-2.4,1-5,1.7-7.6,2c2.8-1.6,4.9-4.1,5.9-7.1c-2.6,1.5-5.4,2.6-8.5,3.1c-2.4-2.5-5.9-4.1-9.7-4.1
                                c-7.3,0-13.3,5.8-13.3,12.9c0,1,0.1,2,0.3,2.9c-11.1-0.5-20.9-5.7-27.4-13.5c-1.1,1.9-1.8,4.1-1.8,6.5c0,4.5,2.3,8.4,5.9,10.7
                                c-2.2-0.1-4.2-0.6-6-1.6v0.2c0,6.3,4.6,11.5,10.7,12.7c-1.1,0.3-2.3,0.5-3.5,0.5c-0.9,0-1.7-0.1-2.5-0.2c1.7,5.1,6.6,8.9,12.4,9
                                C32.7,67.9,27,70,20.7,70c-1.1,0-2.1-0.1-3.2-0.2c5.9,3.7,12.9,5.8,20.4,5.8c24.5,0,37.9-19.7,37.9-36.7l0-1.7
                                C78.4,35.3,80.6,33.1,82.4,30.5z"></path>
                        </svg>			
                    </a>
                    {/* <a className="hlink socli" href="#">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" >
                            <g>
                                <rect x="20.3" y="34" fill="#00a6a6" width="12.9" height="42.4"></rect>
                                <path fill="#00a6a6" d="M27.3,19.6h-1c-3.3,0-6,2.7-6,6v0c0,3.3,2.7,6,6,6h1c3.3,0,6-2.7,6-6v0C33.3,22.3,30.6,19.6,27.3,19.6z"></path>
                                <path fill="#00a6a6" d="M75.3,36.6L75.3,36.6c-2.8-2.3-6.5-3.5-10.9-3.5c-3.5,0-6.5,0.9-8.9,2.7c-0.6,0.5-1.3,1.1-1.9,1.9v-3.5
                                    H41.2v42.3H54V53.6c0-2.3,0.3-4.2,0.9-5.6c1.1-2.6,3.1-3.8,6.2-3.8c2.4,0,4,0.8,4.8,2.4c0.5,1,0.7,2.4,0.7,4.3v25.5h13.1V48.3
                                    C79.7,42.9,78.2,39,75.3,36.6z"></path>
                            </g>
                        </svg>				        	
                    </a> */}
                </div>
            </header>
        )
    }
}
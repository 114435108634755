import React, { Component, } from 'react';
import { Button, InputGroup } from "@blueprintjs/core";

import './style.scss'

export default class EmailInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            placeholder: '    Your email here . . . . .',
            isButtonDisabled: true,
            buttonText: 'Submit',
            campaignUrl: 'http://mailchi.mp/3ddc48f1c6a0/welcome',
        }
    }

    handleInputChange = (e) => {
        console.log('handle input change: ', e.target.value);
        const { value } = e.target;
        this.setState({ value, isButtonDisabled: value.length > 0 ? true : false })
    }

    handleButtonClick(e){
        console.log('handle button click: ', e);
    }

    render() {
        return (
            <div className="email-container">
                <input type='email' className="email-input" value={this.state.value} placeholder={this.state.placeholder} onChange={this.handleInputChange}/>
                <Button type='solid' className="email-submit" color='blue' disabled={this.state.isButtonDisabled} onClick={this.handleButtonClick}>
                    <img className="email-submit-icon" src={process.env.PUBLIC_URL + '/img/sentMail.png'}/>
                </Button>
            </div>
        )
    }
}
import React, { Component } from 'react';
import '../../scss/homepage.scss';

// Import Components
import GlobalHeader from '../../components/globalHeader/index'
import EmailInput from './components/emailInput/index';
import Logo from '../../components/logo/index';
import LineBreak from '../../components/lineBreak/index';

class Homepage extends Component {
    render() {
        return (
            <div>
                <GlobalHeader />
                <div className='container'>
                    <div className="header">
                        <Logo />
                        <text className="header-text">
                            This is where the tagline will go for Cubiqle <br />
                            Get ready for ... something inspiring im sure
                        </text>
                    </div>
                    <LineBreak color="#00a6a6"/>
                    <div className="content">
                        <div className="join-us">
                            <div className="ju-main">
                                <text className="ju-text">Join Us</text> <br />
                                <text className="ju-text-sub">Don't worry, we wont spam you</text>
                            </div>
                            <img src={process.env.PUBLIC_URL + '/img/mailbox.png'} className="ju-mailbox"/>
                        </div>
                        <EmailInput />
                    </div>
                </div>
            </div>
        );
    }
}

export default Homepage;
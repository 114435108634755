import React, { Component } from 'react';

export default class LineBreak extends Component {
    render() {
        console.log('rendering lineBreak', this.props.color);
        return (
            <hr style={{
                width: this.props.width || '80%',
                color: this.props.color || '#000000',
                height: '1px',
                backgroundColor: this.props.color || "#000000",
            }} />
        )
    }
}